@import "base/variables";

.contact-button {
    background-color: $orange_color;
    border: 1px solid $orange_color;
    padding: 0.56rem 1rem;
    color: $white;
    text-decoration: none;
    display: inline-block;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 5px;
    &:hover {
        border: 1px solid $primary_color;
        color: $primary_color;
    }
}

.orange-color {
    color: $orange_color;
}

.about {
    padding: 100px;
}

.footer {
    position: relative;
    height: 300px; /* Set the height of your footer */
    overflow: hidden;
}

.image-container {
    position: relative;
    height: 100%;
}
