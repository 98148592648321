@import "../../base/_variables.scss";

.header-container {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
    margin-top: 30px !important;
    white-space: nowrap;
    .contact-button {
        background-color: $orange_color;
        border: 1px solid $orange_color;
        padding: 0.56rem 1rem;
        color: $white;
        text-decoration: none;
        display: inline-block;
        padding: 10px 20px;
        font-weight: bold;
        border-radius: 5px;
        &:hover {
            border: 1px solid $primary_color;
            color: $primary_color;
        }
    }
    a {
        color: $primary_color;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.header-line {
    background: $primary_color;
    color: $white;
    height: 30px;
    font-size: 12px;
    padding-left: 8rem !important;
    padding-right: 8rem !important;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    a {
        color: $white;
        text-decoration: none;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.img-logo {
    width: 20%;
}

@media (max-width: 768px) {
    .header-container {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 0px !important;
    }

    .header-line {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .img-logo {
        width: 60%;
    }
}

@media (max-width: 480px) {
    .header-line {
        font-size: 10px;
    }
}
