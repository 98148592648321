@import "../../base/_variables.scss";

.image {
    width: 33.3%;
    object-fit: cover;
    opacity: 0.8; /* Set opacity as needed */
    z-index: -1; /* Place the images behind the content and overlay */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $orange_color; /* Orange color with opacity */
    opacity: 0.5;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff; /* Set the text color */
    text-align: center;
    z-index: 1; /* Ensure the content is on top of the overlay */
}

.content h2 {
    margin: 0;
    font-size: 24px; /* Adjust the font size */
}

.content p {
    margin: 10px 0;
}

.logo-footer {
    width: 50%;
}

.footer-span {
    font-size: 12px;
    color: $primary_color;
}

@media (max-width: 768px) {
    .logo-footer {
        width: 100%;
    }

    .footer-span {
        font-size: 14px;
    }

    .footer {
        margin-top: 25px;
    }

    .image:first-of-type {
        display: none;
    }

    .second {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 480px) {
    .logo-footer {
        width: 220px;
    }
}
