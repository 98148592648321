@import "../../base/_variables.scss";

.card-container {
    display: flex;
    overflow: hidden;
    transition: transform 0.5s; /* Add a transition for smooth swapping animation */
    justify-content: center;
    margin-top: 55px;
}

.about-card {
    flex: 0 0 300px;
    padding: 60px 30px;
    margin: 40px 20px;
    background-color: $light_grey;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    cursor: pointer;
    position: relative;
    transition:
        transform 0.5s,
        background-color 0.5s;

    &:hover {
        transform: scale(1.1);
        background-color: $orange_color;
        z-index: 2;
        color: $white;

        .card-image {
            filter: brightness(0) invert(1) sepia(1) saturate(5) hue-rotate(180deg);
        }
    }

    p {
        line-height: 1.4;
    }
}

.card-image {
    width: 50px;
    position: absolute;
    bottom: 35px;
    right: 25px;
    filter: invert(78%) sepia(0%) saturate(1%) hue-rotate(25deg) brightness(94%) contrast(87%);
}

.about-card.active {
    transform: scale(1.3);
    background-color: $orange_color;
    z-index: 2;
    color: $white;

    .card-image {
        width: 50px;
        position: absolute;
        bottom: 35px;
        right: 25px;
        filter: brightness(0) invert(1) sepia(1) saturate(5) hue-rotate(180deg) !important;
    }
}

@media (max-width: 768px) {
    .card-container {
        display: block;
    }

    .about-card.active {
        transform: scale(1.1);
    }

    span {
        font-size: 1em;
        padding: 0 20px;
    }
}

@media (max-width: 480px) {
}
