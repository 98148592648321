@import "../../base/_variables.scss";

.services-container {
    position: relative;
    margin-top: 121px;
    height: auto; /* Adjust the height as needed */
    background-color: $light_grey; /* Set your desired background color */
    z-index: 0;
    box-shadow:
        0 10px 10px -10px rgba(0, 0, 0, 0.5),
        0 -10px 10px -10px rgba(0, 0, 0, 0.5);
    // padding: 100px 0;
}

.services-background-image {
    position: relative;
    width: 100%;
    height: auto; /* Adjust the height as needed */
    background-image: url("../../background-home.jpg");
    background-size: cover;
    background-position: center;
    z-index: 1;
    color: #fff;
    text-align: center;
    padding: 150px 0 230px 0; /* Adjust padding as needed */
    clip-path: polygon(0 0, 8% 0, 29% 100%, 0% 100%, 0 80%);
}

.services-image {
    clip-path: polygon(0 0, 75% 0%, 100% 100%, 25% 100%);
    margin-bottom: 5px; /* To counteract the skew applied by clip-path */
    transition: transform 0.3s ease;
}

.bg-services-image {
    position: absolute;
    bottom: 0;
    width: 600px;
    height: 100%;
    object-fit: center;
    object-fit: cover;
    object-position: center right;
}

.inner-services-container {
    padding: 100px 50px !important;
}

.services-details {
    background: $orange_color;
    position: absolute;
    width: 100%;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 5;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.services-card {
    padding: 0 !important;
    position: relative;
    &:hover {
        cursor: pointer;
        .services-image {
            transform: scale(1.1);
        }
    }
}

.project-card {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    margin: 25px 0;

    .card {
        padding: 20px;
        background-color: rgb(0 0 0 / 0.45);
        border-radius: 0;
        border: none;

        .card-title {
            font-size: 20px;
            margin-bottom: 0;
            color: white;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            color: white;
            font-weight: 400;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 25px;
            max-height: 50px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .read-more-btn {
            color: white;

            &:after {
                background-color: white;
            }
        }

        .image-holder {
            height: 120px;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 75%;
        }
    }

    &:hover {
        text-decoration: none;

        .read-more-btn {
            color: white;
            transition: all 0.1s cubic-bezier(0.49, 0.99, 0.54, 0.98);
            text-decoration: none;

            &:before {
                opacity: 1;
                transform: translateX(4px);
                transition: all 0.1s cubic-bezier(0.49, 0.99, 0.54, 0.98) 0.1s;
            }

            &:after {
                transform: scaleX(0);
                transition: all 0.1s cubic-bezier(0.49, 0.99, 0.54, 0.98);
            }
        }

        .card {
            border-color: blue;
            //           box-shadow: 0 5px 22px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.1);

            .card-body {
                .read-more {
                    &:after {
                        opacity: 1;
                        -webkit-transform: translateX(5px);
                        -moz-transform: translateX(5px);
                        -ms-transform: translateX(5px);
                        -o-transform: translateX(5px);
                        transform: translateX(5px);
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .inner-services-container {
        padding: 15px 0px 0px 25px !important;
    }

    .services-image {
        clip-path: none;
    }

    .services-container {
        margin-top: 0;
        padding: 20px;
    }

    .services-card {
        margin-bottom: 25px;
        &:hover {
            .services-image {
                transform: scale(1);
            }
        }
    }
}

@media (max-width: 480px) {
}
