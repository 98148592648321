@import "../../base/_variables.scss";

.additional-container {
    background-color: $light_grey;
    padding: 100px;
}

.additional-info {
    width: 200px;
}

.additional-icons {
    width: 60px;
    margin-top: -35px;
    margin-right: 15px;
}

.additional-col {
    justify-content: center;
}

@media (max-width: 768px) {
    .additional-col {
        justify-content: center;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .additional-container {
        padding: 25px;
        margin-top: -1px;
    }
}

@media (max-width: 480px) {
    .additional-col {
        justify-content: center;
    }
}
