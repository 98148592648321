@import "../../base/_variables.scss";

.contacts-container {
    padding: 100px;
    background: $light_grey;
}

.input-label {
    position: relative;
    display: inline-block;
    margin-bottom: 40px;
}

.input-label input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000; /* Default border color */
    outline: none;
    height: 40px;
    padding: 5px;
}

.input-label.active {
    color: orange; /* Label color when active */
}

.input-label.active input {
    border-bottom: 1px solid orange; /* Border bottom color when active */
}

.input-label input:placeholder-shown + span {
    opacity: 0;
    transform: translateY(10px);
    transition:
        opacity 0.3s ease,
        transform 0.3s ease;
}

.input-label span {
    position: absolute;
    bottom: 5px;
    left: 0;
    margin-left: 10px;
    margin-bottom: 5px;
    transition:
        opacity 0.3s ease,
        transform 0.3s ease;
}

.contacts-card {
    background: linear-gradient(to right, $white 0%, $white 85%, $orange_color 85%, $orange_color 100%);
    margin: 100px;
    box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.5);
    color: $primary_color;
    button {
        background: $primary_color;
        color: $white;
        padding: 5px;
        font-weight: 500;
        border: 2px solid $primary_color;
        text-transform: uppercase;
        letter-spacing: 8px;
        &:hover {
            background: $white;
            color: $primary_color;
        }
    }
}

.info-card {
    background: $primary_color;
    color: $white;
    height: 65%;
    width: 100%;
    padding: 30px;
    position: relative;
}

.orange-box {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    background-color: $orange_color;
}

.contacts-first-col {
    padding: 60px;
}

.info-components {
    color: $white;
    font-size: 20px;
    margin: 35px 5px;
    a {
        color: $white;
        text-decoration: none;
    }

    svg {
        font-size: 20px;
        margin-right: 15px;
    }
    &:hover {
        a {
            text-decoration: underline;
        }
    }
}

@media (max-width: 768px) {
    .contacts-container {
        padding: 20px;
    }

    .contacts-card {
        margin: 0;
    }

    .contacts-first-col {
        padding: 0;
    }

    .info-card {
        height: 100%;
    }

    .info-components {
        svg {
            font-size: 15px;
            margin-right: 15px;
        }

        span {
            padding: 0;
            font-size: 15px;
        }
    }

    .contacts-card {
        background: linear-gradient(to right, $white 0%, $white 75%, $orange_color 75%, $orange_color 100%);
    }
}

@media (max-width: 480px) {
    .contacts-container {
        padding: 20px;
    }

    .orange-box {
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
    }
}
