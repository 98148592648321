@import "../../base/_variables.scss";

.home-container {
    position: relative;
    margin-top: 121px;
    height: auto; /* Adjust the height as needed */
    background-color: $light_grey; /* Set your desired background color */
    z-index: 0;
}

.home-background-image {
    position: relative;
    width: 100%;
    height: auto; /* Adjust the height as needed */
    background-image: url("../../background-home.jpg");
    background-size: cover;
    background-position: center;
    z-index: 1;
    color: #fff;
    text-align: center;
    padding: 150px 0 230px 0; /* Adjust padding as needed */
    clip-path: polygon(0 0, 100% 0, 100% 68%, 33% 100%, 0 80%);
}

.home-background-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    z-index: 2;
}

.text-content {
    position: relative;
    z-index: 3; /* Ensure the text is above the overlay */
}

.home-bg-border {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #fbc235;
    z-index: 4; /* Place the border above the overlay and text */
}

/* Additional styles for text content */
.text-content h6 {
    margin-bottom: 10px;
}

.text-content h1 {
    margin-bottom: 20px;
    font-size: 2.5em;
}

.text-content span {
    display: block;
}

.tool-class {
    width: 200px;
    position: absolute;
    right: 5%;
    bottom: 0%;
}

@media (max-width: 768px) {
    .home-container {
        margin-top: 55px;
        h1 {
            font-size: 2em;
        }

        h6 {
            font-size: 1.2em;
        }
        span {
            font-size: 1em;
            padding: 0 20px;
        }

        .tool-class {
            width: 140px;
            right: 3%;
            bottom: 2%;
        }
        .home-background-image {
            clip-path: polygon(0 0, 100% 0, 100% 72%, 28% 100%, 0 90%);
            padding: 120px 0 200px 0;
        }
    }
}

@media (max-width: 480px) {
    .home-container {
        margin-top: 55px;
    }
}
